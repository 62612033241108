<template>
    <div class="exception-body access">
        <img src="/assets/layout/images/logo-white.svg" alt="diamond-layout" class="logo"/>

        <div class="exception-content">
            <div class="exception-title">Доступ закрыт</div>
            <div class="exception-detail">Для доступа к запрашиваемому ресурсу пройдите авторизацию</div>
            <router-link :to="{name:'login'}">Авторизация</router-link>
        </div>
    </div>
</template>

<script>
export default {}
</script>
